import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Bars from "./svgs/bars";
import Computer from "./svgs/computer";
import Hammer from "./svgs/hammer";
import Handshake from "./svgs/handshake";
import LightBulb from "./svgs/lightBulb";
import Notes from "./svgs/notes";
import PaperCheck from "./svgs/paperCheck";

export default function LowFalloutRates(): ReactElement {
    const list = [
        {
            title: "Crafting & Reviewing Messages",
            icon: <LightBulb />,
        },
        {
            title: "Ensuring Compliance",
            icon: <PaperCheck />,
        },
        {
            title: "Strategic Media Planning & Procurement",
            icon: <Notes />,
        },
        {
            title: "Lead Capture & Qualification",
            icon: <Bars />,
        },
        {
            title: "Securing Signed Retainers",
            icon: <Handshake />,
        },
        {
            title: "Complete Case Handling",
            icon: <Hammer />,
        },
        {
            title: "Data Analytics & Campaign Enhancement",
            icon: <Computer />,
        },
    ];
    return (
        <section
            className={`${styles["fallout-rate-section"]} relative py-8 md:p-16`}
        >
            <div className="mx-auto max-w-7xl">
                <div className="text-center text-white flex flex-col gap-10 items-center p-4">
                    <h2 className="text-3xl md:text-5xl font-semibold ">
                        Exceptionally Low Fallout Rates
                    </h2>
                    <p className="text-white text-base md:text-xl  leading-[160%] w-full">
                        We consistently outperform industry standards for
                        fallout rates by delivering targeted messages to
                        prospective clients in their online spaces. Our
                        proprietary analytics ensure precise lead generation,
                        delivering high-quality leads with precision.
                    </p>
                    <h2 className="text-3xl md:text-5xl font-semibold ">
                        Case Acquisition Workflow
                    </h2>
                    <img
                        className="hidden md:block"
                        src="/assets/images/processImg2.png"
                        alt="Our Process"
                    />
                    <div className="block md:hidden">
                        {list.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`flex items-center border-2 border-[#dd1f26] p-4 my-8 gap-8 ${styles["item"]}`}
                                >
                                    {item.icon}
                                    <span className="text-left ">
                                        {item.title}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </section>
    );
}
