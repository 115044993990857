import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { DomainInfo } from "@components/severalbrands/utils/services";
import initialCall from "@components/severalbrands/utils/initialCall";
import MetaTags from "@components/torts/shared/metaTags";
import { getKeyWordsForMetaTags } from "@components/severalbrands/utils";
import Hero from "@components/torts/hero";
import CompetitiveAdvantage from "@components/torts/competitiveAdvantage";
import Cases from "@components/torts/cases";
import Process from "@components/torts/process";
import HotTorts from "@components/torts/hotTorts";
import LowFalloutRates from "@components/torts/lowFalloutRates";
import ContactUsModal from "@components/torts/contactUsModal";
import TortsWrapper from "@components/layouts/tortsWrapper";

export default function Home({ domain }: { domain: DomainInfo }): ReactElement {
    return (
        <TortsWrapper>
            <MetaTags
                title={domain?.title}
                description="Torts.com: Case Acquisition Experts"
                keyWords={getKeyWordsForMetaTags(domain)}
            />
            <Hero />
            <CompetitiveAdvantage />
            <Cases />
            <Process />
            <HotTorts />
            <LowFalloutRates />
            <ContactUsModal />
        </TortsWrapper>
    );
}

// @ts-ignore
export const getServerSideProps: GetServerSideProps = async (ctx) => {
    return await initialCall("HomePage", ctx, "torts.com");
};
