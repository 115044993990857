import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import { useModalContext } from "@components/torts/modalContext";

export default function Cases(): ReactElement {
    const { setModalOpen } = useModalContext();

    return (
        <section className={`${styles["cases-section"]} relative py-8 md:p-16`}>
            <div className="mx-auto max-w-7xl">
                <div className="text-center text-white flex flex-col gap-10 items-center p-4">
                    <h2 className="text-3xl md:text-5xl font-semibold ">
                        Supercharge Your Case Load
                    </h2>
                    <h3 className="text-[#ACACAC] text-base md:text-xl  leading-[160%] w-full md:w-2/3">
                        Torts.com powers leading legal brands nationwide.
                        Whether you seek high-dollar mass tort settlements or
                        market domination, we have you covered.
                    </h3>
                    <button
                        onClick={() => setModalOpen(true)}
                        className={`${styles["button"]}  text-xl`}
                    >
                        Get More Cases
                    </button>
                </div>
            </div>
        </section>
    );
}
